import React from 'react'
import Link from 'gatsby-link'

const page = '...'

class Page extends React.Component {
  t = (msg, params) => this.props.intl.formatMessage(msg, params);

  componentDidMount() {
    if (window && window.onload) {
      try {
        window.onload();
      } catch(e){}
    }
  }

  render() {
    const { lang = 'en', list } = this.props
    return (
      <div>
        <div className="main">
          {list}
        </div>
      </div>
    )
  }
}

export default (Page);
