import React from 'react'
import Layout from 'layouts/en'
import Template from 'templates/faq'

export default ({ justContent }) => {
  if (justContent) {
    return (
      <Template lang="en" list={list} />
    )
  }

  return (
    <Layout p={5}>
      <Template lang="en" list={list} />
    </Layout>
  )
}

const list = (
  <section className="template">
    <h1 className="headline">FAQ</h1>
    <article className="faq show">
      <div className="question">
        <p className="text bold">What is Stairlin and why do you exist?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Stairlin is both an idea and a company. Our company was founded in
          Lausanne with offices in Zurich. We are focused on building the first
          truly digitised marketplace for services in Switzerland and Europe.
          <br />
          As a company we are obsessed by finding smart ways to get rid of the
          unnecessary thinking that clods our minds every day of the week and
          drags us down: Did we send this bill to the insurance? Did we call
          back to cancel the appointment? When will I know if the reservation is
          confirmed? We want to eliminate these thousands of paper cuts that eat
          away minutes and hours of our lives so we can focus on what really
          matters in the end: better relationships with our friends, families,
          and customers.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">What problems are you solving?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Searching, booking and paying for services is still a pain in
          Switzerland and Europe in general. For small service providers
          bookings, payments and customer management are done manually in most
          cases. All tasks revolving around bookings are also mainly manual
          (send or track invoices, check who has paid or not, and so on).
          <br /> This is why we want to build a marketplace for services, where
          anyone can easily start or run its business online and where anyone
          can find, book and pay for what they need in 15 seconds or less.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">How can I start using Stairlin?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          All you need is to register on our landing page and set up your
          business profile. After a few minutes, you can already offer your
          services online 24/7 anywhere on the internet to your customers
          (including through Google, Facebook, Instagram, your website etc).
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Is Stairlin compatible with T590?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Yes! The invoice Stairlin generate automatically for you are fully
          compliant with T590 and accepted by the insurances.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">How can I register my business on Stairlin?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          All you have to do is select register on our landing page. From there
          you can create your business in less than two minutes. You only have
          to provide some essential information like which industry your
          business operates in, a short description of your business, and a few
          other details.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          How can I set up my business on Stairlin so that people can book my
          services?
        </p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          To set up your business hours schedule: click on the Stairlin logo,
          select business settings, calendar and finally availabilities.
          <br /> To set up what services you offer: click on the Stairlin logo,
          select business settings then services.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          Do I need to install or download anything for Stairlin to work?
        </p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          There is no need to install anything for Stairlin to work. All you
          need is a web browser on a phone, tablet, computer, whatever you use
          every day, and an internet connection.
          <br /> That being said, we might include an app in the future to
          improve the user experience even more on phones and tablets, but that
          will be optional to use as well.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Where can my customers book my services?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          With Stairlin your customers can book your services wherever you want.
          You know your customer best: do you get in touch with them through
          Facebook? Through Instagram? Through your website? From Google? All
          you have to do is link your Stairlin Business page to these platforms
          and your customers can book your services right away.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          I use social networks or my website to interact with my customers, do
          I need to change that now for people to book my services online?
        </p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          No you do not! We want you to use whatever channel you like best at
          the moment, but simply make it better, so that your customers can more
          easily book and pay for your services. Have a Facebook account? No
          problem, you can add a “book now” button that redirects to your
          Stairlin page. Same goes for any other method you use at the moment,
          just provide a link to your Stairlin business page on them.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">
          I never had to use any password, is that normal?
        </p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          Yes, it is by design! Passwords are difficult to remember and tend to
          be reused. Data breaches happen every day and a single breach can
          potentially expose your password to people with malicious intentions.
          That is why we have decided to approach our authentication system from
          another angle. Three different factors exist to authenticate a user.
          The first is knowledge (something you know), which includes passwords,
          PINs, etc. The second one is possession (something you have), which
          includes phone numbers, emails, key fobs, etc. The last one is
          inherence (something you are), which contains fingerprint, facial
          recognition, etc.
          <br />
          Our authentication system uses the factor possession as the first
          layer of security. Every time you attempt to access your account from
          an unknown device, a 6 digit SMS code will be delivered to your phone
          number so that only the user who possesses the phone number can access
          the account.
          <br />
          We plan to roll out multi-factor authentication in a near future to
          further demonstrate our commitment to secure your account.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">Are communication and data storage secure?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          All communications between your device and Stairlin take place via a
          secure and encrypted channel. We currently use TLS 1.2 with an RSA
          2048 bits key. All our servers communicate via a virtual network
          isolated from other servers.
          <br />
          All your sensitive data is stored encrypted in a PCI DSS compliant and
          ISO 27001 certified data centres governed by strict data privacy laws.
        </p>
      </div>
    </article>
    <article className="faq show">
      <div className="question">
        <p className="text bold">How do I cancel/delete my account?</p>
        <img className="arrow" src="/images/arrow.inline.svg" alt="" />
      </div>
      <div className="answer">
        <p className="text ans">
          If you are a business owner, switch to your business account, head to
          the business settings, and click the 'Delete Account' link under the
          'Account' heading there.
          <br /> Once your business account deleted, you can then delete your
          personal account from the user settings.
        </p>
      </div>
    </article>
  </section>
);
